import Cookies from "universal-cookie";
import axios from "axios";

const cookies = new Cookies();
const token = cookies.get("TOKEN");

const GetData = async (url) => {
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

const AddData = async (url, data) => {
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 1200000,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

const UpdateData = async (url, data) => {
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};
const DeleteData = async (url) => {
  try {
    const response = await axios.delete(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export { GetData, AddData, UpdateData, DeleteData };
