// LoadingErrorMessageComponent.js

import React from "react";
import loadingGIF from "../assets/images/loading-gif.gif";
import PropTypes from "prop-types";

const LoadingErrorMessageComponent = ({
  isCheckingRights,
  isAllowed,
  isAllowedResponse,
}) => {
  LoadingErrorMessageComponent.propTypes = {
    isCheckingRights: PropTypes.bool.isRequired,
    isAllowed: PropTypes.bool.isRequired,
    isAllowedResponse: PropTypes.string.isRequired,
  };

  return (
    <div>
      {isCheckingRights === true && (
        <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>
          <span>Checking rights... Please wait...</span>
          <img
            src={loadingGIF}
            alt=""
            style={{ width: "30px", height: "30px" }}
          />
        </div>
      )}

      {isAllowed === false && (
        <span style={{ color: "orangered" }}>{isAllowedResponse}</span>
      )}
    </div>
  );
};

export default LoadingErrorMessageComponent;
