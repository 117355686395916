import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";

import loadingGIF from "../../assets/images/loading-gif.gif";
import { FaSort } from "react-icons/fa";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { DeleteData, GetData } from "../../api/GeneralFile";
import AddNew from "./AddNew";
import Update from "./Update";
import View from "./View";
import ViewUserList from "./ViewUserList";
import Swal from "sweetalert2";

const SchoolLogin = () => {
  const [displayAddNew, setDisplayAddNew] = useState(false);
  const [displayUpdate, setDisplayUpdate] = useState(false);
  const [displayUserList, setDisplayUserList] = useState(false);
  const [userToBeUpdated, setUserToBeUpdated] = useState({});
  const [schoolDetails, setSchoolDetails] = useState({});
  const [users, setUsers] = useState([]);
  const [displayView, setDisplayView] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsLimit, setRowsLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchBy, setSearchBy] = useState("School Name");

  const { t } = useTranslation();

  useEffect(() => {
    searchData();
  }, [pageNumber, rowsLimit, displayAddNew, displayUpdate]);

  const searchData = () => {
    setIsLoading(true);

    GetData(
      `/gfoExamSchoolLogin/get-all?searchBy=${searchBy}&search=${searchInput}&pageNumber=${pageNumber}&rowsLimit=${rowsLimit}`,
    )
      .then((response) => {
        console.log(response, "response");
        setUsers(response.data.results.paginatedUsers);
        setTotalPage(response.data.results.totalPage);
        if (response.data?.message === false) {
          toast(response.data?.message);
        }
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setSearchInput(event.target.value);
      searchData();
    }
  };

  // sorting
  const [sortOrder, setSortOrder] = useState("asc");

  const sortByColumn = (columnName) => {
    const sortComparator = (key, order) => (a, b) => {
      const comparison = a[key] > b[key] ? 1 : -1;
      return order === "asc" ? comparison : -comparison;
    };

    if (columnName === "_id" || columnName === "School Name") {
      const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
      const sortedUsers = users
        .slice()
        .sort(sortComparator(columnName, newSortOrder));
      setSortOrder(newSortOrder);
      setUsers(sortedUsers);
    }
  };

  const deleteHandler = (id) => {
    Swal.fire({
      title: "Confirm removal of this School?",
      showDenyButton: true,
      confirmButtonText: "Yes remove it",
      denyButtonText: `No don't remove it`,
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteData(`/gfoExamSchoolLogin/delete/${id}`)
          .then((response) => {
            toast(response.data.message);
            searchData();
          })
          .catch((error) => {
            console.log(error);
            console.error(error.response.data.message);
          });
      }
    });
  };

  return (
    <div style={{ marginTop: "15px" }}>
      {displayAddNew && (
        <AddNew
          displayAddNew={displayAddNew}
          setDisplayAddNew={setDisplayAddNew}
        />
      )}

      {displayUpdate && (
        <Update
          user={userToBeUpdated}
          displayUpdate={displayUpdate}
          setDisplayUpdate={setDisplayUpdate}
        />
      )}

      {displayView && (
        <View
          user={userToBeUpdated}
          displayView={displayView}
          setDisplayView={setDisplayView}
        />
      )}
      {displayUserList && (
        <ViewUserList
          school={schoolDetails}
          displayUserList={displayUserList}
          setDisplayUserList={setDisplayUserList}
        />
      )}

      {!displayAddNew && !displayUpdate && !displayView && !displayUserList && (
        <div style={{ textAlign: "center" }}>
          {isLoading ? (
            <img
              src={loadingGIF}
              alt="loading"
              style={{
                width: "50px",
                height: "50px",
                margin: "0 auto",
                marginTop: "50px",
              }}
            />
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: "5px" }}>
                  {/* <Button
                    size="small"
                    variant="contained"
                    onClick={(event) =>
                      setDisplayAddNew((displayAddNew) => true)
                    }
                  >
                    {t("Add")}
                  </Button> */}
                </div>

                <div style={{ display: "flex", gap: "10px" }}>
                  <FormControl>
                    <InputLabel id="select-label">{t("Searchby")}</InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      label="Search by"
                      size="small"
                      value={searchBy}
                      onChange={(event) => setSearchBy(event.target.value)}
                    >
                      <MenuItem value="schoolName">{t("schoolName")}</MenuItem>
                      <MenuItem value="email">{t("Email")}</MenuItem>
                    </Select>
                  </FormControl>
                  <form noValidate>
                    <TextField
                      size="small"
                      label={t("Searchhere")}
                      value={searchInput}
                      autoComplete="off"
                      onChange={(event) => setSearchInput(event.target.value)}
                      onKeyDown={handleKeyPress}
                    />
                  </form>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setSearchInput("")}
                  >
                    {t("Clear")}
                  </Button>
                  <Button variant="contained" size="small" onClick={searchData}>
                    {t("Filter")}
                  </Button>
                </div>
              </div>

              <TableContainer
                component={Paper}
                sx={{ marginTop: "5px", height: 440 }}
              >
                <Table size="small">
                  <TableHead sx={{ backgroundColor: "#333", color: "#fff" }}>
                    <TableRow>
                      <TableCell sx={{ color: "#fff" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {t("Id")}{" "}
                          <FaSort
                            style={{ padding: "5px", backgroundColor: "#000" }}
                            onClick={(event) => sortByColumn("_id")}
                          />
                        </div>
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {t("schoolName")}{" "}
                          <FaSort
                            style={{ padding: "5px", backgroundColor: "#000" }}
                            onClick={(event) => sortByColumn(t("schoolName"))}
                          />
                        </div>
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>{t("Email")}</TableCell>
                      <TableCell sx={{ color: "#fff" }}>
                        {t("Phone No")}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>View Users</TableCell>
                      {/* <TableCell sx={{ color: "#fff" }}>
                        {t("CreatedAt")}
                      </TableCell>
                      <TableCell sx={{ color: "#fff" }}>Updated at</TableCell> */}

                      {/* <TableCell sx={{ color: "#fff" }}>
                        {t("Actions")}
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.map((user, index) => (
                      <TableRow key={user._id}>
                        <TableCell>{user._id}</TableCell>
                        <TableCell>{user.schoolName}</TableCell>

                        <TableCell>{user.email || "-"}</TableCell>
                        <TableCell>{user.phoneNo || "-"}</TableCell>
                        <TableCell>
                          {" "}
                          <Button
                            size="small"
                            variant="contained"
                            sx={{ marginRight: "5px" }}
                            onClick={(event) => {
                              setSchoolDetails(user);
                              setDisplayUserList(true);
                            }}
                          >
                            {" "}
                            <RemoveRedEyeIcon />{" "}
                          </Button>
                        </TableCell>

                        {/* 
                        <TableCell>
                          <Button
                            size="small"
                            variant="contained"
                            sx={{ marginRight: "5px" }}
                            onClick={(event) => {
                              setUserToBeUpdated((userToBeUpdated) => user);
                              setDisplayUpdate(true);
                            }}
                          >
                            {" "}
                            <EditIcon />
                          </Button>
                          <Button
                            size="small"
                            variant="contained"
                            sx={{ marginRight: "5px" }}
                            onClick={(event) => {
                              setUserToBeUpdated((userToBeUpdated) => user);
                              setDisplayView(true);
                            }}
                          >
                            {" "}
                            <RemoveRedEyeIcon />{" "}
                          </Button>
                          <Button
                            size="small"
                            variant="contained"
                            sx={{ marginRight: "5px" }}
                            onClick={(event) => {
                              deleteHandler(user._id);
                            }}
                          >
                            {" "}
                            <DeleteIcon />{" "}
                          </Button>
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "30px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "15px" }}
                >
                  <Typography>{t("RPP")}</Typography>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      {t("Rows")}
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rowsLimit}
                      label="Rows limit"
                      onChange={(event) => {
                        setRowsLimit(event.target.value);
                        setPageNumber(1);
                      }}
                    >
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="15">15</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "0px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <SkipPreviousIcon
                    onClick={(event) =>
                      setPageNumber((pageNumber) =>
                        pageNumber <= 1 ? 1 : --pageNumber,
                      )
                    }
                  />
                  <div style={{ minWidth: "50px", textAlign: "center" }}>
                    {pageNumber} of {totalPage}
                  </div>
                  <SkipNextIcon
                    onClick={(event) =>
                      setPageNumber((pageNumber) =>
                        pageNumber < totalPage ? ++pageNumber : totalPage,
                      )
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default SchoolLogin;
