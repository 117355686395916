import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { Box, Tab, Tabs } from "@mui/material";
import { useSelector } from "react-redux";
import LoadingErrorMessageComponent from "./LoadingErrorMessageComponent";
import { useTranslation } from "react-i18next";
import SchoolLogin from "../components/gfoManagement/SchoolLogin";
import NationalDateSet from "../components/gfoManagement/NationalDateSet";
import AllStudent from "../components/gfoManagement/AllStudent";
import StateDateSet from "../components/gfoManagement/StateDateSet";
import DBTimeValue from "../components/gfoManagement/DBTimeValue";

const GFOExam = () => {
  const cookies = new Cookies();

  const token = cookies.get("TOKEN");
  console.log(token);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isAllowedResponse, setIsAllowedResponse] = useState("");
  const [isCheckingRights, setIsCheckingRights] = useState(true);
  const role = useSelector((state) => state.role);
  const { t } = useTranslation();
  useEffect(() => {
    if (token) {
      const configuration = {
        method: "POST",
        url: "/role-management/get",
        data: { role },
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      setIsCheckingRights(true);
      axios(configuration)
        .then((response) => {
          if (response.data?.result?.allowGFOExam === true) {
            setIsAllowed(true);
          } else {
            setIsAllowedResponse(t("NoPermissionStatus"));
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsCheckingRights(false);
        });
    }
  }, [token, role]);

  // tabs
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <LoadingErrorMessageComponent
        isCheckingRights={isCheckingRights}
        isAllowed={isAllowed}
        isAllowedResponse={isAllowedResponse}
      />
      {isAllowed && (
        <div style={{ position: "relative", minHeight: "calc(100vh - 120px)" }}>
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label={t("School_Login")} />
              <Tab label="State Date Set" />
              <Tab label="National Date Set" />
              <Tab label="All Student" />
              <Tab label="Set DB & Time Value" />
            </Tabs>
          </Box>

          {tabValue === 0 ? <SchoolLogin /> : ""}
          {tabValue === 1 ? <StateDateSet /> : ""}
          {tabValue === 2 ? <NationalDateSet /> : ""}
          {tabValue === 3 ? <AllStudent /> : ""}
          {tabValue === 4 ? <DBTimeValue /> : ""}
        </div>
      )}
    </div>
  );
};

export default GFOExam;
