import React from 'react'
import { NavLink } from 'react-router-dom'

const PageNotFound = () => {
    return (
        <div>
            <div style={{ fontSize: "100px", textAlign: "center" }}>
                404
            </div>
            <div style={{ textAlign: "center" }}>
                <b style={{ fontSize: "24px" }}>Oops</b> <br /><br />
                the page dosen't exist or is unavailable
            </div>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
                <NavLink
                    to="/dashboard"
                    style={{
                        padding: "15px",
                        backgroundColor: "#4a45ff",
                        color: "#fff",
                        fontWeight: "bold",
                        textDecoration: "none"
                    }}
                >GO BACK TO HOME</NavLink>
            </div>
        </div>
    )
}

export default PageNotFound