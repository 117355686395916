import "./assets/styles/global.styles.css";
import styles from "./styles.module.scss";
import { useEffect, useRef, useState } from "react";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import LogoutIcon from "@mui/icons-material/Logout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import AppRoutes from "./components/AppRoutes";
import NavigationLinks from "./components/NavigationLinks";

function App() {
  const cookies = new Cookies();
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    axios.defaults.baseURL =
      // "https://api.adm-prod.skitii.com/api/v1/skitii-admin";
      "https://admin.skitii.com/api/v1/skitii-admin";
    // "http://localhost:1198/api/v1/skitii-admin";
  } else {
    axios.defaults.baseURL =
      // "https://api.adm-prod.skitii.com/api/v1/skitii-admin";
      "https://admin.skitii.com/api/v1/skitii-admin";
    // "http://localhost:1198/api/v1/skitii-admin";
  }

  const location = useLocation();

  const navigationContainerRef = useRef(null);
  const [toggleMenu, setToggleMenu] = useState(true);
  useEffect(() => {
    if (toggleMenu) {
      navigationContainerRef.current.style.cssText = `flex: 0 0 280px`;
    } else if (!toggleMenu) {
      navigationContainerRef.current.style.cssText = `flex: 0 0 0`;
    }
  }, [toggleMenu]);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(window.navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      Swal.fire({
        title: "The Internet?",
        text: "That thing is still around?",
        icon: "question",
      });
    }
  }, [isOnline]);

  const handleLogout = () => {
    Swal.fire({
      title: "Do you want to logout?",
      showDenyButton: true,
      confirmButtonText: "Yes log me out",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        cookies.remove("TOKEN", { path: "/" });
        window.location.href = "/login";
      } else if (result.isDenied) {
        toast("Please try again later!");
      }
    });
  };

  return (
    <div className={styles.container}>
      <div ref={navigationContainerRef} className={styles.navigationContainer}>
        <NavigationLinks />
      </div>

      <div className={styles.contentContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>
            {!toggleMenu && (
              <AiOutlineMenuUnfold
                className={styles.menuIcon}
                onClick={() => setToggleMenu((toggleMenu) => !toggleMenu)}
              />
            )}
            {toggleMenu && (
              <AiOutlineMenuFold
                className={styles.menuIcon}
                onClick={() => setToggleMenu((toggleMenu) => !toggleMenu)}
              />
            )}
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              {location.pathname === "/dashboard" ? "SKITII - Dashboard" : ""}
              {location.pathname === "/user-management"
                ? "SKITII - User Management"
                : ""}
              {location.pathname === "/admin-management"
                ? "SKITII - Admin Management"
                : ""}
              {location.pathname === "/institute-management"
                ? "SKITII - Institute Management"
                : ""}
              {location.pathname === "/bundled-d2c"
                ? "SKITII - Bundled D2C Management"
                : ""}
              {location.pathname === "/content-management"
                ? "SKITII - Content Management"
                : ""}
              {location.pathname === "/subscription-settings"
                ? "SKITII - Subscription Settings"
                : ""}
              {location.pathname === "/global-settings"
                ? "SKITII - Global Settings"
                : ""}
              {location.pathname === "/settings" ? "SKITII - Settings" : ""}
              {location.pathname === "/downloads" ? "SKITII - Downloads" : ""}
              {location.pathname === "/gfo-exam" ? "SKITII - GFO Exam" : ""}
              {location.pathname === "/referral-program"
                ? "SKITII - Referral Program"
                : ""}
              {location.pathname === "/institute-dashboard"
                ? "SKITII - Institute Dashboard"
                : ""}
              {location.pathname === "/institute/tikTok"
                ? "SKITII - Tik Tok Data"
                : ""}
              {location.pathname === "/institute/userDetails"
                ? "SKITII - User Data"
                : ""}
              {/* {location.pathname === "/institute/breakCount"
                ? "SKITII - Break Count Data"
                : ""} */}
              {location.pathname === "/institute/moodDetails"
                ? "SKITII - User Emotion"
                : ""}
              {location.pathname === "/institute/bestMood"
                ? "SKITII - Feedback Improvement"
                : ""}
              {location.pathname === "/institute/GfoCount"
                ? "SKITII - GFO History"
                : ""}
            </div>
          </div>
          <LogoutIcon
            style={{ fontSize: "30px", fontWeight: "bold", color: "orangered" }}
            onClick={handleLogout}
          />
        </div>

        <div className={styles.contentInnerContainer}>
          <div className={styles.routesContainer}>
            <hr
              style={{
                marginTop: "15px",
                marginBottom: "15px",
                border: "none",
                borderTop: "1px solid #aaa",
                backgroundColor: "inherit",
              }}
            />
            {/* routes */}
            <AppRoutes />
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default App;
