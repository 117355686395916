import React, { useState, useEffect } from "react";
import skitiiLogo from "../assets/images/logo.png";
import {
  Button,
  TextField,
  Typography,
  inputLabelClasses,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import loadingImage from "../assets/images/loading-gif.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Cookies from "universal-cookie";
import CryptoJS from "crypto-js";

const Login = () => {
  const cookies = new Cookies();
  const [isLoading, setIsLoading] = useState(false);
  const [admin, setAdmin] = useState({ email: "", password: "" });
  const [passwordBoxType, setPasswordBoxType] = useState("password");

  const getRoleAndRedirect = async (token) => {
    const adminConfig = {
      method: "POST",
      url: "/admin/get",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    try {
      const adminResponse = await axios(adminConfig);
      const role = adminResponse.data.message.admin.role;
      if (role === "Institute") {
        window.location.href = "/institute-dashboard";
      } else {
        window.location.href = "/dashboard";
      }
    } catch (error) {
      console.error(error);
      toast("Failed to fetch user role");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = cookies.get("TOKEN");
    if (token) {
      getRoleAndRedirect(token);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!admin.email) {
      return toast("Please enter your email");
    }
    if (!admin.password) {
      return toast("Please enter your password");
    }
    // if (!/^(\w+)@(\w+)(\.[a-zA-Z]{2,5}){1,2}$/.test(admin.email)) {
    //   toast("Invalid email");
    //   return;
    // }
    // if (
    //   !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/.test(
    //     admin.password,
    //   )
    // ) {
    //   toast(
    //     "Password [8-15], 1 uppercase, 1 lowercase, 1 number and 1 special character required",
    //   );
    //   return;
    // }

    const secretKey = "a2rp";
    const encryptedPassword = CryptoJS.AES.encrypt(
      JSON.stringify(admin.password),
      secretKey,
    ).toString();

    const configuration = {
      method: "POST",
      url: "/admin/login",
      data: { email: admin.email, encryptedPassword },
    };

    setIsLoading(true);
    axios(configuration)
      .then((response) => {
        toast(response?.data?.message);
        if (response.data.success === true) {
          const token = response.data.token;
          cookies.set("TOKEN", token, { path: "/" });
          getRoleAndRedirect(token);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (
          err.response.status === 400 ||
          err.response.status === 401 ||
          err.response.status === 403
        ) {
          setIsLoading(false);
          return toast(err.response.data.message);
        }
        setIsLoading(false);
        console.error(err);
        toast(err?.message);
      });
  };

  return (
    <div
      style={{
        position: "fixed",
        left: "0",
        top: "0",
        backgroundColor: "#fff",
        width: "100%",
        height: "100%",
        padding: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          textAlign: "center",
          padding: "50px",
          backgroundColor: "aliceblue",
          minWidth: "280px",
          width: "500px",
        }}
      >
        <img
          src={skitiiLogo}
          alt=""
          style={{ width: "30px", height: "30px" }}
        />
        <Typography variant="h5">Welcome to Mindful Gurukul</Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="email"
            label="Email address"
            name="email"
            sx={{ marginTop: "30px" }}
            InputLabelProps={{
              sx: {
                color: "#aaa",
                [`&.${inputLabelClasses.shrink}`]: {},
              },
            }}
            inputProps={{
              style: {},
              autoComplete: "new-password",
              form: { autoComplete: "off" },
            }}
            value={admin.email}
            onChange={(event) =>
              setAdmin({ ...admin, email: event.target.value.toLowerCase() })
            }
            required
          />

          <div
            style={{ width: "100%", position: "relative", marginTop: "30px" }}
          >
            <TextField
              type={passwordBoxType}
              fullWidth
              label="Password"
              name="password"
              InputLabelProps={{
                sx: {
                  color: "#aaa",
                  [`&.${inputLabelClasses.shrink}`]: {},
                },
              }}
              inputProps={{
                autoComplete: "new-password",
                form: { autoComplete: "off" },
              }}
              value={admin.password}
              onChange={(event) =>
                setAdmin({ ...admin, password: event.target.value })
              }
              required
            />
            {passwordBoxType === "text" && (
              <VisibilityIcon
                sx={{
                  position: "absolute",
                  margin: "auto",
                  top: "0",
                  bottom: "0",
                  right: "15px",
                }}
                onClick={() => setPasswordBoxType("password")}
              />
            )}
            {passwordBoxType === "password" && (
              <VisibilityOffIcon
                sx={{
                  position: "absolute",
                  margin: "auto",
                  top: "0",
                  bottom: "0",
                  right: "15px",
                }}
                onClick={() => setPasswordBoxType("text")}
              />
            )}
          </div>

          <div
            style={{ marginTop: "30px", overflow: "hidden", height: "30px" }}
          >
            {isLoading ? (
              <img
                src={loadingImage}
                alt=""
                style={{ width: "30px", height: "30px" }}
              />
            ) : (
              <Button type="submit" variant="contained" fullWidth>
                Submit
              </Button>
            )}
          </div>
        </form>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Login;
