import React, { useRef, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { UpdateData } from "../../api/GeneralFile";

const EditUserData = (props) => {
  console.log(props.user, "props.user");
  const theme = createTheme({
    components: {
      MuiFormLabel: { styleOverrides: { asterisk: { color: "red" } } },
    },
  });
  const { t } = useTranslation();
  const myForm = useRef(null);
  const user = props.user;

  const [name, setName] = useState(user?.name);
  const [grade, setGrade] = useState(user?.grade);
  const [dob, setDOB] = useState(
    user?.dob ? new Date(user?.dob)?.toISOString().split("T")[0] : "",
  );
  const [hallTicket, setHallTicket] = useState(
    user?.hallticket ? user?.hallticket : "",
  );
  const [phone, setPhone] = useState(user?.phoneNo);

  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [offLineIntraMarks, setOffLineIntraMarks] = useState(
    user?.OfflineIntraMarks,
  );
  const [offLineStateMarks, setOffLineStateMarks] = useState(
    user?.OfflineStateMarks,
  );
  const [offLineNationalMarks, setOffLineNationalMarks] = useState(
    user?.OfflineNationalMarks,
  );

  const [intraSchoolMarks, setIntraSchoolMarks] = useState(
    user?.IntraSchoolMarks,
  );
  const [stateMarks, setStateMarks] = useState(user?.StateMarks);
  const [nationalMarks, setNationalMarks] = useState(user?.NationalMarks);

  const [intraSchoolTime, setIntraSchoolTime] = useState(user?.IntraSchoolTime);
  const [stateTime, setStateTime] = useState(user?.StateTime);
  const [nationalTime, setNationalTime] = useState(user?.NationalTime);

  const handlePhoneNumberChange = (event) => {
    const regex = /\D/gi;
    const value = event.target.value.replace(regex, "").slice(0, 10);
    setPhone(value);
    if (value.length !== 10) {
      setPhoneNumberError("Size: 10  Chars, 0-9");
    } else {
      setPhoneNumberError("");
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const generateHallTicket = () => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const randomLetters =
      letters.charAt(Math.floor(Math.random() * letters.length)) +
      letters.charAt(Math.floor(Math.random() * letters.length));
    const randomDigits = String(Math.floor(Math.random() * 1000)).padStart(
      3,
      "0",
    );
    setHallTicket(`${randomLetters}${randomDigits}`);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (phoneNumberError) {
      toast("Form contains errors");
      return;
    }
    const inputData = {
      schoolid: props?.schoolid,
      userid: user._id,
      name,
      grade,
      dob,
      hallTicket,
      phoneNo: phone,
      offlineIntraMarks: offLineIntraMarks,
      offlineNationalMarks: offLineNationalMarks,
      offlineStateMarks: offLineStateMarks,
      IntraSchoolMarks: intraSchoolMarks,
      StateMarks: stateMarks,
      NationalMarks: nationalMarks,
      IntraSchoolTime: intraSchoolTime,
      StateTime: stateTime,
      NationalTime: nationalTime,
    };

    setIsLoading(true);
    UpdateData("/gfoExamSchoolLogin/update-student", inputData)
      .then((response) => {
        console.log(response, "response add user");
        toast(response.data.message);
        props.searchData();
        if (myForm.current.buttonId === "exit") {
          if (response.data.success) {
            props.setDisplayUpdate((displayUpdate) => false);
          }
        }
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  EditUserData.propTypes = {
    user: PropTypes.object.isRequired,
    setDisplayUpdate: PropTypes.func.isRequired,
    searchData: PropTypes.func.isRequired,
    schoolid: PropTypes.string.isRequired,
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ paddingBottom: "50px" }}>
        <Typography variant="h4">Update Student Data</Typography>

        <form autoComplete="off" onSubmit={handleSubmit} ref={myForm}>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label="Name"
              required
              value={name}
              onChange={(event) => setName(event.target.value)}
              disabled
            />
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label="Grade"
              required
              value={grade}
              onChange={(event) => setGrade(event.target.value)}
              disabled
            />
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            {/* <label>DOB</label> */}
            <TextField
              fullWidth
              label="DOB"
              type="date"
              InputLabelProps={{ shrink: true }}
              // required
              inputProps={{ max: new Date().toISOString().split("T")[0] }}
              value={dob}
              onChange={(e) =>
                setDOB(
                  e.target.value
                    ? new Date(e.target.value)?.toISOString().split("T")[0]
                    : "",
                )
              }
              disabled
            />
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label="Hall Ticket No."
              type="text"
              required
              value={hallTicket}
              onChange={(e) => setHallTicket(e.target.value)}
            />
            <Button variant="contained" onClick={generateHallTicket}>
              Generate Hall Ticket No.
            </Button>
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label={t("Phone No")}
              type="text"
              required
              value={phone}
              onChange={handlePhoneNumberChange}
              error={phoneNumberError.length > 0}
              helperText={phoneNumberError.length > 0 ? phoneNumberError : ""}
            />
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label="OffLine Intra School Marks"
              type="text"
              value={offLineIntraMarks}
              onChange={(e) => setOffLineIntraMarks(e.target.value)}
            />
          </div>
          {/* {props.category === "State" && props.category === "National" && ( */}
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label="OffLine State Marks"
              type="text"
              value={offLineStateMarks}
              onChange={(e) => setOffLineStateMarks(e.target.value)}
            />
          </div>
          {/* // )} */}
          {/* {props.category === "National" && ( */}
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label="OffLine National Marks"
              type="text"
              value={offLineNationalMarks}
              onChange={(e) => setOffLineNationalMarks(e.target.value)}
            />
          </div>
          {/* // )} */}
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label="OnLine Intra School Marks"
              type="text"
              value={intraSchoolMarks}
              onChange={(e) => setIntraSchoolMarks(e.target.value)}
              // disabled
            />
          </div>{" "}
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label="OnLine State Marks"
              type="text"
              value={stateMarks}
              onChange={(e) => setStateMarks(e.target.value)}
              // disabled
            />
          </div>{" "}
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label="OnLine National Marks"
              type="text"
              value={nationalMarks}
              onChange={(e) => setNationalMarks(e.target.value)}
              // disabled
            />
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label="OnLine Intra School Time"
              type="text"
              value={intraSchoolTime}
              onChange={(e) => setIntraSchoolTime(e.target.value)}
              // disabled
            />
          </div>{" "}
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label="OnLine State Time"
              type="text"
              value={stateTime}
              onChange={(e) => setStateTime(e.target.value)}
              // disabled
            />
          </div>{" "}
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label="OnLine National Time"
              type="text"
              value={nationalTime}
              onChange={(e) => setNationalTime(e.target.value)}
              // disabled
            />
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              onClick={(e) => (myForm.current.buttonId = "continue")}
            >
              {t("Save_and_continue")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              onClick={(e) => (myForm.current.buttonId = "exit")}
            >
              {t("Save_and_exit")}
            </Button>
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={() => props.setDisplayUpdate((displayUpdate) => false)}
            >
              Back
            </Button>
          </div>
        </form>

        <ToastContainer />
      </div>
    </ThemeProvider>
  );
};

export default EditUserData;
