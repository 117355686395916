import { createSlice } from '@reduxjs/toolkit';

const initialState = {
        role : '',
        InsEmail:"",
        
  }; 
  const inputSlice = createSlice({
    name: 'UserData',
    initialState,
    reducers: {
      setNavRole: (state, action) => {
        state.role= action.payload;
      },
      setInsEmail: (state, action) => {
        state.InsEmail= action.payload;
      },
    
    },
  });
  
  export const { setNavRole , setInsEmail } = inputSlice.actions;
  export default inputSlice.reducer;