
import React from "react";
import { Outlet } from "react-router-dom";


import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function ProtectedRoutes() {
    const token = cookies.get("TOKEN");
    if (token) {
        return <Outlet />;
    } else {
        window.location.href = "/login";
    }
    return <ToastContainer />
}
