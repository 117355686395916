import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AddData, GetData } from "../../api/GeneralFile";
import { toast } from "react-toastify";

const StateDateSet = () => {
  const [user, setUser] = useState([]);

  const [state, setState] = useState();
  const [distinctState, setDistinctState] = useState([]);
  const [stateExamDate, setStateExamDate] = useState("");

  const [stateResultDate, setStateResultDate] = useState("");

  const [stateExamTime, setStateExamTime] = useState(
    user[0]?.stateExamTime ? user[0]?.stateExamTime.split(" to ")[0] : "",
  );

  const [stateExamTime2, setStateExamTime2] = useState(
    user[0]?.stateExamTime ? user[0]?.stateExamTime.split(" to ")[1] : "",
  );
  const [stateCameraPermission, setStateCameraPermission] = useState(
    user?.stateCameraPermission ? user?.stateCameraPermission : "",
  );
  const [stateAudioPermission, setStateAudioPermission] = useState(
    user?.stateAudioPermission ? user?.stateAudioPermission : "",
  );
  const searchData = () => {
    GetData(`/gfoExamSchoolLogin/stateDate/selection/get-user?state=${state}`)
      .then((response) => {
        console.log(response.data, "response");
        setUser(response.data.results.paginatedUsers);
        setDistinctState(response.data.distinctState);
        setStateExamDate(
          response.data.results.paginatedUsers[0]?.stateExamdate
            ? new Date(response.data.results.paginatedUsers[0]?.stateExamdate)
                ?.toISOString()
                .split("T")[0]
            : "",
        );
        setStateResultDate(
          response.data.results.paginatedUsers[0]?.stateResultdate
            ? new Date(response.data.results.paginatedUsers[0]?.stateResultdate)
                ?.toISOString()
                .split("T")[0]
            : "",
        );
        setStateCameraPermission(
          response.data.results.paginatedUsers[0]?.stateCameraPermission
            ? response.data.results.paginatedUsers[0]?.stateCameraPermission
            : "",
        );
        setStateAudioPermission(
          response.data.results.paginatedUsers[0]?.stateAudioPermission
            ? response.data.results.paginatedUsers[0]?.stateAudioPermission
            : "",
        );

        setStateExamTime(
          response.data.results.paginatedUsers[0]?.stateExamTime
            ? response.data.results.paginatedUsers[0]?.stateExamTime.split(
                " to ",
              )[0]
            : "",
        );

        setStateExamTime2(
          response.data.results.paginatedUsers[0]?.stateExamTime
            ? response.data.results.paginatedUsers[0]?.stateExamTime.split(
                " to ",
              )[1]
            : "",
        );
      })
      .catch((err) => {
        console.error(err, "errpr");
      });
  };

  useEffect(() => {
    searchData();
  }, [state]);

  const handleSubmit = () => {
    const stateExamTimeGap = `${stateExamTime} to ${stateExamTime2}`;
    if (!state) {
      return toast.error("Please Select a State");
    }
    const data = {
      stateExamDate,
      stateResultDate,
      stateExamTime: stateExamTimeGap,
      state,
      stateCameraPermission: stateCameraPermission,
      stateAudioPermission: stateAudioPermission,
    };
    AddData(`/gfoExamSchoolLogin/update-all/samestate/school`, data)
      .then((response) => {
        console.log(response, "response");
        toast.success("Dates updated successfully");
      })
      .catch((err) => {
        console.error(err, "error");
        toast.error(err.response?.data?.message || "An error occurred");
      });
  };

  return (
    <>
      <div
        style={{
          marginTop: "15px",
          display: "flex",
          justifyContent: "space-around",
          gap: "30px",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="select-label">Select State</InputLabel>
          <Select
            labelId="select-label"
            fullWidth
            id="select"
            label="Select State"
            value={state}
            onChange={(event) => setState(event.target.value)}
          >
            {distinctState?.length > 0 &&
              distinctState?.map((state, index) => (
                <MenuItem value={state} key={String(index + 1)}>
                  {state}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth></FormControl>
      </div>
      <div
        style={{
          marginTop: "15px",
          display: "flex",
          justifyContent: "space-around",
          gap: "30px",
        }}
      >
        <h1>State Exam Date</h1>
        <h1>State Exam Time</h1>
        <h1>State Result Date</h1>
      </div>
      <div style={{ display: "flex", gap: "30px" }}>
        <TextField
          fullWidth
          type="date"
          // label="State Exam Date"

          inputProps={{
            min: !stateExamDate && new Date().toISOString().split("T")[0],
          }}
          value={stateExamDate}
          onChange={(event) =>
            setStateExamDate(
              event.target.value
                ? new Date(event.target.value)?.toISOString().split("T")[0]
                : "",
            )
          }
        />
        <div>
          <TextField
            fullWidth
            type="time"
            value={stateExamTime}
            onChange={(e) => setStateExamTime(e.target.value)}
          />
        </div>
        <div>
          <TextField
            fullWidth
            type="time"
            value={stateExamTime2}
            onChange={(e) => setStateExamTime2(e.target.value)}
          />
        </div>

        <TextField
          fullWidth
          type="date"
          // label="State Result Date"

          inputProps={{
            min: !stateResultDate && new Date().toISOString().split("T")[0],
          }}
          value={stateResultDate}
          onChange={(event) =>
            setStateResultDate(
              event.target.value
                ? new Date(event.target.value)?.toISOString().split("T")[0]
                : "",
            )
          }
        />
      </div>
      <div style={{ display: "flex", gap: "30px", marginTop: "15px" }}>
        <FormControl fullWidth>
          <InputLabel id="status-select-label">Camera Permission</InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            label="Camera Permission"
            required
            value={stateCameraPermission}
            onChange={(event) => setStateCameraPermission(event.target.value)}
          >
            <MenuItem value="On">On</MenuItem>
            <MenuItem value="Off">Off</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="status-select-label">Audio Permission</InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            label="Audio Permission"
            required
            value={stateAudioPermission}
            onChange={(event) => setStateAudioPermission(event.target.value)}
          >
            <MenuItem value="On">On</MenuItem>
            <MenuItem value="Off">Off</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
        <Button
          type="submit"
          variant="contained"
          // disabled={isLoading}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default StateDateSet;
