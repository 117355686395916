import { Button, FormControl, InputLabel, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AddData, GetData } from "../../api/GeneralFile";
import { toast } from "react-toastify";

const DBTimeValue = () => {
  const [db, setDB] = useState("");
  const [timeValue, setTimeValue] = useState("");

  const searchData = () => {
    GetData(`/gfoExamSchoolLogin/get-all`)
      .then((response) => {
        console.log(response, "response");

        setDB(
          response.data.results.paginatedUsers[0]?.dbValue
            ? response.data.results.paginatedUsers[0]?.dbValue
            : "",
        );

        setTimeValue(
          response.data.results.paginatedUsers[0]?.alertDisplayTime
            ? response.data.results.paginatedUsers[0]?.alertDisplayTime
            : "",
        );
      })
      .catch((err) => {
        console.error(err, "error");
      });
  };

  useEffect(() => {
    searchData();
  }, []);

  const handleSubmit = () => {
    const data = {
      dbValue: db,
      alertdisplaytime: timeValue,
    };
    AddData(`/gfoExamSchoolLogin/set/dbandtime/value`, data)
      .then((response) => {
        console.log(response, "response");
        toast.success("Dates updated successfully");
      })
      .catch((err) => {
        console.error(err, "error");
        toast.error(err.response?.data?.message || "An error occurred");
      });
  };

  return (
    <>
      <div style={{ display: "flex", gap: "30px", marginTop: "15px" }}>
        <TextField
          fullWidth
          label="Db Value"
          type="text"
          required
          value={db}
          onChange={(e) => setDB(e.target.value)}
          //   error={emailError.length > 0}
          //   helperText={emailError.length > 0 ? emailError : ""}
        />
        <TextField
          fullWidth
          label="Alert Display Time(second)"
          type="text"
          required
          value={timeValue}
          onChange={(e) => setTimeValue(e.target.value)}
          //   error={emailError.length > 0}
          //   helperText={emailError.length > 0 ? emailError : ""}
        />
      </div>
      <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
        <Button
          type="submit"
          variant="contained"
          // disabled={isLoading}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default DBTimeValue;
