import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { AddData } from "../../api/GeneralFile";
import { State } from "country-state-city";

const AddNew = ({ displayAddNew, setDisplayAddNew }) => {
  const theme = createTheme({
    components: {
      MuiFormLabel: { styleOverrides: { asterisk: { color: "red" } } },
    },
  });
  const { t } = useTranslation();
  const myForm = useRef(null);
  const allStates = State.getStatesOfCountry("IN");
  const [schoolName, setSchoolName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");

  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleEmailChange = (event) => {
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const isValid = regex.test(event.target.value.toLowerCase());
    setEmail(event.target.value.toLowerCase());
    if (!isValid) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const handlePhoneNumberChange = (event) => {
    const regex = /\D/gi;
    const value = event.target.value.replace(regex, "").slice(0, 10);
    setPhone(value);
    if (value.length !== 10) {
      setPhoneNumberError("Size: 10  Chars, 0-9");
    } else {
      setPhoneNumberError("");
    }
  };

  const handlePasswordChange = (event) => {
    const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}/;
    const value = event.target.value;
    setPassword(value);
    if (value.length < 8) {
      setPasswordError(
        "Password must contain at least one digit, one lowercase letter, one uppercase letter, and be at least 8 characters long.",
      );
    } else {
      setPasswordError("");
    }
  };
  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setState((state) => selectedState);
  };
  const [isLoading, setIsLoading] = useState(false);

  const generatePassword = () => {
    const lowerChars = "abcdefghijklmnopqrstuvwxyz";
    const upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const digits = "0123456789";
    const specialChars = "!@$^*";
    const allChars = lowerChars + upperChars + digits + specialChars;
    const passwordLength = 8; // Desired password length

    const getRandomChar = (charset) =>
      charset[Math.floor(Math.random() * charset.length)];

    let password = "";
    password += getRandomChar(lowerChars);
    password += getRandomChar(upperChars);
    password += getRandomChar(digits);
    password += getRandomChar(specialChars);

    for (let i = 4; i < passwordLength; i++) {
      password += getRandomChar(allChars);
    }

    setPassword(password);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (emailError || phoneNumberError) {
      toast("Form contains errors");
      return;
    }

    const inputData = {
      schoolName,
      email,
      password,
      phoneNo: phone,
      address,
      state,
    };
    console.log(inputData, "inputdata");
    setIsLoading(true);
    AddData("/gfoExamSchoolLogin/add", inputData)
      .then((response) => {
        console.log(response, "response add user");
        toast.success(response.data.message);

        if (myForm.current.buttonId === "exit") {
          if (response.data.success) {
            setDisplayAddNew((displayAddNew) => false);
          }
        }
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast.error(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ paddingBottom: "50px" }}>
        <Typography variant="h4">{t("Add_new_school")}</Typography>

        <form autoComplete="off" onSubmit={handleSubmit} ref={myForm}>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label="School Name"
              required
              value={schoolName}
              onChange={(event) => setSchoolName(event.target.value)}
            />
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label={t("Email")}
              type="email"
              required
              value={email}
              onChange={handleEmailChange}
              error={emailError.length > 0}
              helperText={emailError.length > 0 ? emailError : ""}
            />
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label={t("Password")}
              type="text"
              required
              value={password}
              onChange={handlePasswordChange}
              error={passwordError.length > 0}
              helperText={passwordError.length > 0 ? passwordError : ""}
            />
            <Button variant="contained" onClick={generatePassword}>
              {t("Generate Password")}
            </Button>
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label={t("Phone No")}
              type="text"
              required
              value={phone}
              onChange={handlePhoneNumberChange}
              error={phoneNumberError.length > 0}
              helperText={phoneNumberError.length > 0 ? phoneNumberError : ""}
            />
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              type="text"
              label="Address"
              required
              InputProps={{ inputProps: { min: 0, max: 999 } }}
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </div>{" "}
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <FormControl fullWidth>
              <InputLabel id="state-select-label">{t("state")}</InputLabel>
              <Select
                labelId="state-select-label"
                id="state-select"
                label={t("state")}
                value={state}
                onChange={handleStateChange}
                required
              >
                {allStates?.map((state) => (
                  <MenuItem
                    value={state.name}
                    key={state.isoCode}
                    name={state.name}
                    // onClick={() => handleStateClick(state)}
                  >
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              onClick={(e) => (myForm.current.buttonId = "continue")}
            >
              {t("Save_and_continue")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              onClick={(e) => (myForm.current.buttonId = "exit")}
            >
              {t("Save_and_exit")}
            </Button>
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={() => setDisplayAddNew(false)}
            >
              {/* {t("Cancel")} */}Back
            </Button>
          </div>
        </form>

        <ToastContainer />
      </div>
    </ThemeProvider>
  );
};

AddNew.propTypes = {
  setDisplayAddNew: PropTypes.func.isRequired,
};

export default AddNew;
