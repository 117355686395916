import { Button } from "@mui/material";
import React from "react";
import * as XLSX from "xlsx";
import PropTypes from "prop-types";
const XLSXDownload = (props) => {
  const handleDownload = async() => {
    const allData = await props.fetchData();
    console.log(allData)
    const ws = XLSX.utils.json_to_sheet(allData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, props.name);
    XLSX.writeFile(wb, props.name + ".xlsx");
  };
  XLSXDownload.propTypes = {
    fetchData: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  };
  return (
    <Button variant="contained" size="small" onClick={handleDownload}>
      Export file as excel sheet
    </Button>
  );
};

export default XLSXDownload;
