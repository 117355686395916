import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import XLSXDownload from "./XLSXDownload";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { toast } from "react-toastify";
import { GetData } from "../../api/GeneralFile";
import { useSelector } from "react-redux";

const GFOhistory = ({ isInstitute }) => {
  const liveHistory = [];
  const [searchInput, setSearchInput] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsLimit, setRowsLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tiktok, setTiktok] = useState();
  const apiEndPoint = isInstitute ? "InsGfoCount" : "GFO";
  const insEmail = useSelector((state) => state.InsEmail);
  const email = isInstitute ? insEmail : "";
  const getAllTikTOk = () => {
    GetData(`/downloads/${apiEndPoint}?category=${searchInput}&startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&rowsLimit=${rowsLimit}
            &email=${email}`)
      .then((response) => {
        setTiktok(response.data?.paginatedResults.results);
        setTotalPage(response.data.paginatedResults.totalPage);
      })
      .catch((err) => {
        console.error(err, "error");
        toast(err.response.data?.message);
      });
  };

  useEffect(() => {
    getAllTikTOk();
  }, [pageNumber, rowsLimit]);

  const fetchAllTikTOkData = () => {
    return GetData(
      `/downloads/${apiEndPoint}?category=${searchInput}&startDate=${startDate}&endDate=${endDate}&email=${email}&pageNumber=1&rowsLimit=999999`,
    )
      .then((response) => {
        return response.data?.paginatedResults.results.map((activity) => ({
          Name: activity.userDetails?.Name || "-",
          Phone: activity.userDetails?.Phone || "-",
          Count: activity.count || "-",
        }));
      })
      .catch((err) => {
        console.error(err, "error");
        toast(err.response.data?.message);
        return [];
      });
  };

  const menuItems = [
    { key: "1", value: "General", text: "General" },
    { key: "2", value: "1st", text: "1st" },
    { key: "3", value: "2nd", text: "2nd" },
    { key: "4", value: "3rd", text: "3rd" },
    { key: "5", value: "4th", text: "4th" },
    { key: "6", value: "5th", text: "5th" },
    { key: "7", value: "6th", text: "6th" },
    { key: "8", value: "7th", text: "7th" },
    { key: "9", value: "8th", text: "8th" },
    { key: "10", value: "9th", text: "9th" },
    { key: "11", value: "10th", text: "10th" },
    { key: "12", value: "11th", text: "11th" },
    { key: "13", value: "12th", text: "12th" },
  ];
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
          <Typography variant="h4">GFO History</Typography>
          <XLSXDownload fetchData={fetchAllTikTOkData} name="GFO history" />
        </div>
        <div>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Category{" "}
            </FormLabel>
            <Select
              labelId="group-select-label"
              id="group-select"
              label="Option"
              size="small"
              value={searchInput}
              onChange={(event) => setSearchInput(event.target.value)}
            >
              {menuItems.map((item) => (
                <MenuItem key={item.key} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <TextField
              type="date"
              value={startDate}
              onChange={(event) => {
                setStartDate(event.target.value);
              }}
            />
          </FormControl>

          <FormControl>
            <TextField
              type="date"
              value={endDate}
              onChange={(event) => {
                setEndDate(event.target.value);
              }}
            />
          </FormControl>

          <Button variant="contained" onClick={getAllTikTOk}>
            Filter
          </Button>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#333", color: "#fff" }}>
              <TableCell sx={{ color: "#fff" }}>Name</TableCell>
              <TableCell sx={{ color: "#fff" }}>Mobile number</TableCell>
              <TableCell sx={{ color: "#fff" }}>Total Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tiktok?.map((activity, index) => (
              <TableRow key={index}>
                <TableCell>{activity.userDetails?.Name}</TableCell>
                <TableCell>{activity.userDetails?.Phone}</TableCell>
                <TableCell>{activity.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div
        style={{
          marginTop: "15px",
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
        }}
      >
        <FormControl>
          <InputLabel id="select-label">Rows</InputLabel>
          <Select
            size="small"
            labelId="select-label"
            id="select"
            value={rowsLimit}
            label="Rows limit"
            onChange={(event) => {
              setRowsLimit(event.target.value);
              setPageNumber(1);
            }}
          >
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="15">15</MenuItem>
          </Select>
        </FormControl>
        <div
          style={{
            display: "flex",
            gap: "0px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <SkipPreviousIcon
            onClick={(event) =>
              setPageNumber((pageNumber) =>
                pageNumber <= 1 ? 1 : --pageNumber,
              )
            }
          />
          <div style={{ minWidth: "50px", textAlign: "center" }}>
            {pageNumber} of {totalPage}
          </div>
          <SkipNextIcon
            onClick={(event) =>
              setPageNumber((pageNumber) =>
                pageNumber < totalPage ? ++pageNumber : totalPage,
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default GFOhistory;
