import React, { useRef, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { State } from "country-state-city";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { UpdateData } from "../../api/GeneralFile";

const Update = (props) => {
  console.log(props.user, "props.user");
  const theme = createTheme({
    components: {
      MuiFormLabel: { styleOverrides: { asterisk: { color: "red" } } },
    },
  });
  const { t } = useTranslation();
  const myForm = useRef(null);
  const user = props.user;

  const [schoolName, setSchoolName] = useState(user?.schoolName);
  const [email, setEmail] = useState(user?.email);
  const [password, setPassword] = useState(user?.password);
  const [phone, setPhone] = useState(user?.phoneNo);
  const [address, setAddress] = useState(user?.address);
  const [state, setState] = useState(user?.state);
  const [intraSchoolExamDate, setIntraSchoolExamDate] = useState(
    user?.intraschoolExamdate
      ? new Date(user?.intraschoolExamdate)?.toISOString().split("T")[0]
      : "",
  );

  const [intraSchoolResultDate, setIntraSchoolResultDate] = useState(
    user?.intraschoolResultdate
      ? new Date(user?.intraschoolResultdate)?.toISOString().split("T")[0]
      : "",
  );
  const [intraSchoolCameraPermission, setIntraSchoolCameraPermission] =
    useState(
      user?.intraschoolCameraPermission
        ? user?.intraschoolCameraPermission
        : "",
    );
  const [intraSchoolAudioPermission, setIntraSchoolAudioPermission] = useState(
    user?.intraschoolAudioPermission ? user?.intraschoolAudioPermission : "",
  );

  const [intraschoolExamTime, setIntraschoolExamTime] = useState(
    user?.intraschoolExamTime ? user?.intraschoolExamTime.split(" to ")[0] : "",
  );

  const [intraschoolExamTime2, setIntraschoolExamTime2] = useState(
    user?.intraschoolExamTime ? user?.intraschoolExamTime.split(" to ")[1] : "",
  );

  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const allStates = State.getStatesOfCountry("IN");
  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setState((state) => selectedState);
  };

  const handleEmailChange = (event) => {
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const isValid = regex.test(event.target.value.toLowerCase());
    setEmail(event.target.value.toLowerCase());
    if (!isValid) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const handlePhoneNumberChange = (event) => {
    const regex = /\D/gi;
    const value = event.target.value.replace(regex, "").slice(0, 10);
    setPhone(value);
    if (value.length !== 10) {
      setPhoneNumberError("Size: 10  Chars, 0-9");
    } else {
      setPhoneNumberError("");
    }
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    if (value.length < 8) {
      setPasswordError(
        "Password must contain at least one digit, one lowercase letter, one uppercase letter, and be at least 8 characters long.",
      );
    } else {
      setPasswordError("");
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const generatePassword = () => {
    const lowerChars = "abcdefghijklmnopqrstuvwxyz";
    const upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const digits = "0123456789";
    const specialChars = "!@$^*";
    const allChars = lowerChars + upperChars + digits + specialChars;
    const passwordLength = 8; // Desired password length

    const getRandomChar = (charset) =>
      charset[Math.floor(Math.random() * charset.length)];

    let password = "";
    password += getRandomChar(lowerChars);
    password += getRandomChar(upperChars);
    password += getRandomChar(digits);
    password += getRandomChar(specialChars);

    for (let i = 4; i < passwordLength; i++) {
      password += getRandomChar(allChars);
    }

    setPassword(password);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (emailError || phoneNumberError) {
      toast("Form contains errors");
      return;
    }

    const intraSchoolExamTimeGap = `${intraschoolExamTime} to ${intraschoolExamTime2}`;

    const inputData = {
      _id: user._id,
      schoolName,
      email,
      password,
      phoneNo: phone,
      address,
      state,
      intraschoolExamdate: intraSchoolExamDate,
      intraschoolResultdate: intraSchoolResultDate,
      intraschoolExamTime: intraSchoolExamTimeGap,
      intraschoolCameraPermission: intraSchoolCameraPermission,
      intraschoolAudioPermission: intraSchoolAudioPermission,
    };

    setIsLoading(true);
    UpdateData("/gfoExamSchoolLogin/update", inputData)
      .then((response) => {
        console.log(response, "response add user");
        toast(response.data.message);

        if (myForm.current.buttonId === "exit") {
          if (response.data.success) {
            props.setDisplayUpdate((displayUpdate) => false);
          }
        }
      })
      .catch((err) => {
        console.error(err, "errpr");
        toast(err.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  Update.propTypes = {
    user: PropTypes.object.isRequired,
    setDisplayUpdate: PropTypes.func.isRequired,
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ paddingBottom: "50px" }}>
        <Typography variant="h4">{t("Update_School")}</Typography>

        <form autoComplete="off" onSubmit={handleSubmit} ref={myForm}>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label="School Name"
              required
              value={schoolName}
              onChange={(event) => setSchoolName(event.target.value)}
            />
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label={t("Email")}
              type="email"
              required
              value={email}
              onChange={handleEmailChange}
              error={emailError.length > 0}
              helperText={emailError.length > 0 ? emailError : ""}
            />
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label={t("Password")}
              type="text"
              required
              value={password}
              onChange={handlePasswordChange}
              error={passwordError.length > 0}
              helperText={passwordError.length > 0 ? passwordError : ""}
            />
            <Button variant="contained" onClick={generatePassword}>
              {t("Generate Password")}
            </Button>
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              label={t("Phone No")}
              type="text"
              required
              value={phone}
              onChange={handlePhoneNumberChange}
              error={phoneNumberError.length > 0}
              helperText={phoneNumberError.length > 0 ? phoneNumberError : ""}
            />
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              type="text"
              label="Address"
              required
              InputProps={{ inputProps: { min: 0, max: 999 } }}
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <FormControl fullWidth>
              <InputLabel id="state-select-label">{t("state")}</InputLabel>
              <Select
                labelId="state-select-label"
                id="state-select"
                label={t("state")}
                value={state}
                onChange={handleStateChange}
              >
                {allStates?.map((state) => (
                  <MenuItem
                    value={state.name}
                    key={state.isoCode}
                    name={state.name}
                    // onClick={() => handleStateClick(state)}
                  >
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              marginTop: "15px",
              display: "flex",
              justifyContent: "space-around",
              gap: "30px",
            }}
          >
            <h1>Intra School Exam Date</h1> <h1>Intra School Exam Time</h1>
            <h1>Intra School Result Date</h1>
          </div>
          <div style={{ display: "flex", gap: "30px" }}>
            <TextField
              fullWidth
              type="date"
              // label="Intra School Exam Date"

              inputProps={{
                min:
                  !intraSchoolExamDate &&
                  new Date().toISOString().split("T")[0],
              }}
              value={intraSchoolExamDate}
              onChange={(event) =>
                setIntraSchoolExamDate(
                  event.target.value
                    ? new Date(event.target.value)?.toISOString().split("T")[0]
                    : "",
                )
              }
            />

            <div>
              <TextField
                fullWidth
                type="time"
                value={intraschoolExamTime}
                onChange={(e) => setIntraschoolExamTime(e.target.value)}
              />
            </div>
            <div>
              <TextField
                fullWidth
                type="time"
                value={intraschoolExamTime2}
                onChange={(e) => setIntraschoolExamTime2(e.target.value)}
              />
            </div>

            <TextField
              fullWidth
              type="date"
              // label="Intra School Result Date"

              inputProps={{
                min:
                  !intraSchoolResultDate &&
                  new Date().toISOString().split("T")[0],
              }}
              value={intraSchoolResultDate}
              onChange={(event) =>
                setIntraSchoolResultDate(
                  event.target.value
                    ? new Date(event.target.value)?.toISOString().split("T")[0]
                    : "",
                )
              }
            />
          </div>
          <div style={{ display: "flex", gap: "30px", marginTop: "15px" }}>
            <FormControl fullWidth>
              <InputLabel id="status-select-label">
                Camera Permission
              </InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                label="Camera Permission"
                required
                value={intraSchoolCameraPermission}
                onChange={(event) =>
                  setIntraSchoolCameraPermission(event.target.value)
                }
              >
                <MenuItem value="On">On</MenuItem>
                <MenuItem value="Off">Off</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="status-select-label">Audio Permission</InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                label="Audio Permission"
                required
                value={intraSchoolAudioPermission}
                onChange={(event) =>
                  setIntraSchoolAudioPermission(event.target.value)
                }
              >
                <MenuItem value="On">On</MenuItem>
                <MenuItem value="Off">Off</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{ marginTop: "15px", display: "flex", gap: "30px" }}>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              onClick={(e) => (myForm.current.buttonId = "continue")}
            >
              {t("Save_and_continue")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              onClick={(e) => (myForm.current.buttonId = "exit")}
            >
              {t("Save_and_exit")}
            </Button>
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={() => props.setDisplayUpdate((displayUpdate) => false)}
            >
              Back
            </Button>
          </div>
        </form>

        <ToastContainer />
      </div>
    </ThemeProvider>
  );
};

export default Update;
